<template>
  <HeaderComponent ref="scrollTop" @experience="goTo('experience')" @work="goTo('work')" @contact="goTo('contact')"></HeaderComponent>
  <HomeComponent></HomeComponent>
  <ExperienceComponent ref="experience"></ExperienceComponent>
  <WorkComponent ref="work"></WorkComponent>
  <ContactComponent ref="contact"></ContactComponent>
  <LeftMenu></LeftMenu>

  <div class="scroll-top" @click="goTo('scrollTop')"><span class="material-symbols-outlined"> keyboard_double_arrow_up </span></div>
</template>
<script setup lang="ts">
import HeaderComponent from '@/components/HeaderComponent.vue'
import ExperienceComponent from '@/components/ExperienceComponent.vue'
import LeftMenu from '@/components/LeftMenu.vue'
import WorkComponent from '@/components/WorkComponent.vue'
import ContactComponent from '@/components/ContactComponent.vue'
import { ref } from 'vue'
import HomeComponent from '@/components/HomeComponent.vue'

const experience = ref(null)
const work = ref(null)
const contact = ref(null)
const scrollTop = ref(null)

const goTo = (param: string) => {
  switch (param) {
    case 'experience':
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      experience.value?.$el.scrollIntoView({ behavior: 'smooth', block: 'center' })
      break
    case 'work':
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      work.value?.$el.scrollIntoView({ behavior: 'smooth', block: 'center' })
      break
    case 'contact':
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      contact.value?.$el.scrollIntoView({ behavior: 'smooth', block: 'center' })
      break
    case 'scrollTop':
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      scrollTop.value?.$el.scrollIntoView({ behavior: 'smooth', block: 'center' })
      break
  }
}
</script>

<style scoped lang="scss">
.scroll-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding-top: 5px;
  width: 40px;
  height: 40px;
  border: 2px solid var(--root-cyan);
  color: var(--root-cyan);

  &:hover {
    color: white;
    border-color: white;
  }
}
</style>
