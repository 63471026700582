<template>
  <div class="background">
    <div class="container">
      <div :class="['g-cursor', { 'g-cursor_hover': hover }, { 'g-cursor_hide': hideCursor }]">
        <div :style="cursorCircle" class="g-cursor__circle"></div>
        <div ref="point" class="g-cursor__point" :style="cursorPoint"></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'

const xChild = ref(0)
const yChild = ref(0)
const xParent = ref(0)
const yParent = ref(0)
const hover = ref(false)
const hideCursor = ref(true)

// Define methods
const moveCursor = (e: { clientX: number; clientY: number }) => {
  xChild.value = e.clientX
  yChild.value = e.clientY
  setTimeout(() => {
    xParent.value = e.clientX - 15
    yParent.value = e.clientY - 15
  }, 100)
}

// Computed properties
const cursorCircle = computed(() => {
  return `transform: translateX(${xParent.value}px) translateY(${yParent.value}px) translateZ(0) translate3d(0, 0, 0);`
})

const cursorPoint = computed(() => {
  return `transform: translateX(${xChild.value - 3}px) translateY(${yChild.value - 3}px) translateZ(0) translate3d(0, 0, 0);`
})

// Add event listeners on mount
onMounted(() => {
  document.addEventListener('mousemove', moveCursor)
  document.addEventListener('mouseleave', () => {
    hideCursor.value = true
  })
  document.addEventListener('mouseenter', () => {
    hideCursor.value = false
  })
})

// Remove event listeners on unmount
onUnmounted(() => {
  document.removeEventListener('mousemove', moveCursor)
})
</script>
<style lang="scss">
* {
  background-color: #1a191d;
}

body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: 'Roboto mono', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.background {
  background-color: var(--root-background);
}

.g-cursor {
  &_hide {
    opacity: 0;
    transition: width 0.6s ease, height 0.6s ease, opacity 0.6s ease;
  }

  &__circle {
    pointer-events: none;
    user-select: none;
    top: 0;
    left: 0;
    position: fixed;
    width: 30px;
    height: 30px;
    border: 2px solid var(--root-cyan);
    border-radius: 100%;
    z-index: 5555;
    backface-visibility: hidden;
    transition: opacity 0.6s ease;
  }

  &__point {
    top: 0;
    left: 0;
    position: fixed;
    width: 7px;
    height: 7px;
    pointer-events: none;
    user-select: none;
    border-radius: 100%;
    background: var(--root-cyan);
    z-index: 55555555;
    backface-visibility: hidden;
    will-change: transform;
  }

  &_hover {
    .g-cursor__circle {
      opacity: 0;
      width: 60px;
      height: 60px;
      transition: width 0.6s ease, height 0.6s ease, opacity 0.6s ease;
    }
  }
}
</style>
