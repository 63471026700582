<template>
  <div class="d-flex side-menu">
    <ul>
      <li><i class="bi bi-linkedin" @click="goToLinkedin"></i></li>
      <li><i class="bi bi-github" @click="goToGithub"></i></li>
    </ul>
  </div>
</template>

<script setup lang="ts">
const goToLinkedin = () => {
  window.open('https://www.linkedin.com/in/dragos-petru-gherghina/', '_blank')
}

const goToGithub = () => {
  window.open('https://github.com/DevDragos', '_blank')
}
</script>

<style scoped lang="scss">
.side-menu {
  position: fixed;
  left: 0;
  bottom: 0;

  color: white;

  & ul {
    list-style: none;
    margin: 20px 20px;
    padding: 0;
  }
  & li {
    margin-top: 10px;
  }
  & i {
    font-size: 20px;
  }
  & i:hover {
    color: var(--root-cyan);
    cursor: pointer;
  }
}
</style>
