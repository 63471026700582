<template>
  <div class="d-flex flex-column justify-content-center" style="height: 500px">
    <div class="d-flex justify-content-center">
      <p class="d-inline-flex gap-1">
        <button
          class="btn btn-primary d-flex flex-row justify-content-between"
          :class="toggle ? 'btn-primary-active' : 'btn-primary'"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapse-1"
          aria-expanded="false"
          aria-controls="collapse-1"
          @click="setToggle"
        >
          <span>Signicat AS </span>
          <span class="d-flex">
            <span>2022 - Present</span>
            <span v-if="toggle" class="material-symbols-outlined my-auto"> remove </span>
            <span v-else class="material-symbols-outlined my-auto"> add </span>
          </span>
        </button>
      </p>
    </div>
    <div id="collapse-1" class="collapse">
      <div class="card card-body">
        Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant
        trigger.
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const toggle = ref<boolean>(false)

const setToggle = () => {
  toggle.value = !toggle.value
}
</script>

<style scoped lang="scss">
.btn-primary {
  width: 500px;
  background-color: var(--root-purple);
  border: none;

  &:hover {
    background-color: var(--root-active-purple);
  }
}
.btn-primary-active {
  background-color: var(--root-active-purple);
}

.card-body {
  background-color: var(--root-background-purple);
  color: var(--root-white);
}
</style>
