<template>
  <div class="nav justify-content-between">
    <a class="navbar-brand" href="#">Logo</a>
    <div class="navbar-nav flex-row">
      <a class="nav-item nav-link" href="#">01. home</a>
      <a class="nav-item nav-link" @click="emit('experience')">02. experience</a>
      <a class="nav-item nav-link" @click="emit('work')">03. work</a>
      <a class="nav-item nav-link" @click="emit('contact')">04. contact</a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineEmits } from 'vue'

const emit = defineEmits(['experience', 'work', 'contact'])
</script>

<style scoped lang="scss">
a {
  padding: 15px;
  color: var(--root-gray);

  &:hover {
    color: var(--root-cyan);
  }

  &:active,
  :visited {
    color: var(--root-gray);
  }
}
</style>
